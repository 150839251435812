/********
 * Front-end CSS file
 ********/

@import "components/fonts.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: white;
}

a, p, h1, h2, h3, h4, strong, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.pin {
  @apply top-0 left-0 right-0 bottom-0
}

.bg-cover-x {
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}

@media (min-width: 1280px) {
  .second-level-nav-item .second-level-nav-dd { display: none; }
  .second-level-nav-item:hover .second-level-nav-dd,
  .second-level-nav-item:focus .second-level-nav-dd,
  .second-level-nav-item:focus-within .second-level-nav-dd {
    display: flex;
  }
}

.content-link {
  @apply text-secondary font-bold underline decoration-primary hover:text-primary underline-offset-4 decoration-1 hover:decoration-secondary hover:decoration-dashed dark:text-primary transition-colors duration-500
}

.content-link-invert {
  @apply text-accent font-bold underline decoration-accent-light hover:text-primary-light underline-offset-2 decoration-1 hover:decoration-accent hover:decoration-dashed transition-colors duration-500
}

.bg-stretch {
  background-size: 100% 100%;
}

@import "components/typography.css";
@import "components/buttons.css";
