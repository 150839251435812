.button {
  @apply inline-flex px-4 md:px-8 pt-4 pb-2 font-subheading font-normal no-underline uppercase antialiased transition-all duration-500 border-2 leading-none tracking-wide justify-center items-center text-center text-2xl lg:text-4xl bg-no-repeat;
  background-size: 100% 100%;
}

.button-primary-outline {
  @apply button border-primary bg-primary text-secondary hover:text-primary;
}

.button-primary {
  @apply text-primary font-subheading text-2xl md:text-3xl underline decoration-2 decoration-primary underline-offset-4 hover:text-primary hover:decoration-dashed hover:decoration-secondary
}

.button-secondary-outline {
  @apply button border-secondary bg-white/10 backdrop-blur hover:bg-secondary text-secondary hover:text-white
}

.button-secondary {
  @apply button bg-secondary hover:bg-secondary-dark text-white border-secondary
}

.button-accent-outline {
  @apply button border-accent backdrop-blur hover:bg-gradient-to-r hover:from-accent-dark hover:to-accent-light text-white hover:text-primary-dark
}

.button-accent {
  @apply button bg-accent hover:bg-primary border-accent text-primary-dark hover:text-white
}

.button-accent-light-outline {
  @apply button border-accent backdrop-blur hover:bg-gradient-to-r hover:from-accent-dark hover:to-accent-light text-white hover:text-primary-dark
}

.button-accent-light {
  @apply  button text-white border-transparent hover:border-4 hover:underline hover:decoration-2 hover:decoration-dashed hover:underline-offset-2
}

.gallery-controls {
  @apply leading-none h-full px-6 text-white flex items-center cursor-pointer font-subheading no-underline bg-no-repeat pt-1;
}
