/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/rubik/rubik-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/rubik/rubik-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/rubik/rubik-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/rubik/rubik-v21-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('/fonts/rubik/rubik-v21-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/rubik/rubik-v21-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/fonts/rubik/rubik-v21-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/rubik/rubik-v21-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/rubik/rubik-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/rubik/rubik-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/rubik/rubik-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/rubik/rubik-v21-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'ThirstyRough';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/thirsty/ThirstyRoughRegularTwo-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/thirsty/ThirstyRoughRegularTwo-webfont.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'VeneerTwo';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/veneertwo/veneertwo.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/veneertwo/veneertwo.woff') format('woff'); /* Modern Browsers */
}
